import * as Blockly from 'blockly/core';

Blockly.Blocks['run'] = {
    validator: function(newValue) {
        var sourceBlock = this.getSourceBlock();
        sourceBlock.showTextField_ = newValue == 'TRUE';
        sourceBlock.updateBlock();

        return newValue;
    },

    updateBlock: function() {
        var input = this.getInput('DUMMY');
        if (this.showTextField_&& !this.getField('for')&& !this.getField('value')&& !this.getField('valueType')) {
            input.appendField(new Blockly.FieldLabelSerializable("for"), "for");
            input.appendField(new Blockly.FieldNumber(0), "value");
            input.appendField(new Blockly.FieldDropdown([["milliseconds","3"], ["rotations","0"], ["degrees","1"], ["seconds","2"]]), "valueType");
        } else if (!this.showTextField_&& this.getField('for')&& this.getField('value')&& this.getField('valueType')) {
            input.removeField("for");
            input.removeField("value");
            input.removeField("valueType");
        }
    },

    dropdownValidator: function(newValue) {
        var sourceBlock = this.getSourceBlock();
        if(newValue=="2"){
            sourceBlock.getField("port").menuGenerator_=[["A+B","0"], ["A+D","1"], ["B+C","2"], ["C+D","3"]];
            switch(sourceBlock.getField("port").selectedOption_[1]){
                case "0":
                    sourceBlock.getField("port").selectedOption_=["A+B","0"];
                    break;
                case "1":
                    sourceBlock.getField("port").selectedOption_=["A+D","1"];
                    break;
                case "2":
                    sourceBlock.getField("port").selectedOption_=["B+C","2"];
                    break;
                case "3":
                    sourceBlock.getField("port").selectedOption_=["C+D","3"];
                    break;
            }
            sourceBlock.getField("port").forceRerender();
        }else{
            sourceBlock.getField("port").menuGenerator_=[["A","0"], ["B","1"], ["C","2"], ["D","3"]];
            switch(sourceBlock.getField("port").selectedOption_[1]){
                case "0":
                    sourceBlock.getField("port").selectedOption_=["A","0"];
                    break;
                case "1":
                    sourceBlock.getField("port").selectedOption_=["B","1"];
                    break;
                case "2":
                    sourceBlock.getField("port").selectedOption_=["C","2"];
                    break;
                case "3":
                    sourceBlock.getField("port").selectedOption_=["D","3"];
                    break;
            }
            sourceBlock.getField("port").forceRerender();
        }
        return newValue;
    },

    init: function() {

        this.appendDummyInput('DUMMY')
            .appendField("run")
            .appendField(new Blockly.FieldDropdown([["medium motor","0"], ["large motor","1"], ["two large motors","2"]],this.dropdownValidator), "motors")
            .appendField(" ")
            .appendField(new Blockly.FieldDropdown([["A","0"], ["B","1"], ["C","2"], ["D","3"]]), "port")
            .appendField("   at")
            .appendField(new Blockly.FieldNumber(50, -100, 100), "power")
            .appendField("%  ")
            .appendField(new Blockly.FieldCheckbox(false,this.validator), "additional");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(135);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['ramp'] = {

    dropdownValidator: function(newValue) {
        var sourceBlock = this.getSourceBlock();
        if(newValue=="2"){
            sourceBlock.getField("port").menuGenerator_=[["A+B","0"], ["A+D","1"], ["B+C","2"], ["C+D","3"]];
            switch(sourceBlock.getField("port").selectedOption_[1]){
                case "0":
                    sourceBlock.getField("port").selectedOption_=["A+B","0"];
                    break;
                case "1":
                    sourceBlock.getField("port").selectedOption_=["A+D","1"];
                    break;
                case "2":
                    sourceBlock.getField("port").selectedOption_=["B+C","2"];
                    break;
                case "3":
                    sourceBlock.getField("port").selectedOption_=["C+D","3"];
                    break;
            }
            sourceBlock.getField("port").forceRerender();
        }else{
            sourceBlock.getField("port").menuGenerator_=[["A","0"], ["B","1"], ["C","2"], ["D","3"]];
            switch(sourceBlock.getField("port").selectedOption_[1]){
                case "0":
                    sourceBlock.getField("port").selectedOption_=["A","0"];
                    break;
                case "1":
                    sourceBlock.getField("port").selectedOption_=["B","1"];
                    break;
                case "2":
                    sourceBlock.getField("port").selectedOption_=["C","2"];
                    break;
                case "3":
                    sourceBlock.getField("port").selectedOption_=["D","3"];
                    break;
            }
            sourceBlock.getField("port").forceRerender();
        }
        return newValue;
    },

    init: function() {
        this.appendDummyInput('DUMMY')
            .appendField("ramp")
            .appendField(new Blockly.FieldDropdown([["medium motor","0"], ["large motor","1"], ["two large motors","2"]],this.dropdownValidator), "motors")
            .appendField(" ")
            .appendField(new Blockly.FieldDropdown([["A","0"], ["B","1"], ["C","2"], ["D","3"]]), "port")
            .appendField("  at")
            .appendField(new Blockly.FieldNumber(50, -100, 100), "power")
            .appendField("%    for")
            .appendField(new Blockly.FieldNumber(0), "value")
            .appendField(new Blockly.FieldDropdown([["milliseconds","3"], ["rotations","0"], ["degrees","1"], ["seconds","2"]]), "valueType")
        /*.appendField(new Blockly.FieldCheckbox(false,validator), "additional")*/;
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(135);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['tankmotors'] = {
    validator: function(newValue) {
        var sourceBlock = this.getSourceBlock();
        sourceBlock.showTextField_ = newValue == 'TRUE';
        sourceBlock.updateBlock();

        return newValue;
    },

    updateBlock: function() {
        var input = this.getInput('DUMMY');
        if (this.showTextField_&& !this.getField('for')&& !this.getField('value')&& !this.getField('valueType')) {
            input.appendField(new Blockly.FieldLabelSerializable("for"), "for");
            input.appendField(new Blockly.FieldNumber(0), "value");
            input.appendField(new Blockly.FieldDropdown([["milliseconds","3"], ["rotations","0"], ["degrees","1"], ["seconds","2"]]), "valueType");
        } else if (!this.showTextField_&& this.getField('for')&& this.getField('value')&& this.getField('valueType')) {
            input.removeField("for");
            input.removeField("value");
            input.removeField("valueType");
        }
    },

    init: function() {
        this.appendDummyInput('DUMMY')
            .appendField("tank motors")
            .appendField(new Blockly.FieldDropdown([["A+B","0"], ["A+D","1"], ["B+C","2"], ["C+D","3"]]), "ports")
            .appendField(new Blockly.FieldNumber(50, -100, 100), "power1")
            .appendField("%")
            .appendField(new Blockly.FieldNumber(50, -100, 100), "power2")
            .appendField("%  ")
            .appendField(new Blockly.FieldCheckbox(false,this.validator), "additional");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(135);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['steermotors'] = {
    validator: function(newValue) {
        var sourceBlock = this.getSourceBlock();
        sourceBlock.showTextField_ = newValue == 'TRUE';
        sourceBlock.updateBlock();

        return newValue;
    },

    updateBlock: function() {
        var input = this.getInput('DUMMY');
        if (this.showTextField_&& !this.getField('for')&& !this.getField('value')&& !this.getField('valueType')) {
            input.appendField(new Blockly.FieldLabelSerializable("for"), "for");
            input.appendField(new Blockly.FieldNumber(0), "value");
            input.appendField(new Blockly.FieldDropdown([["milliseconds","3"], ["rotations","0"], ["degrees","1"], ["seconds","2"]]), "valueType");
        } else if (!this.showTextField_&& this.getField('for')&& this.getField('value')&& this.getField('valueType')) {
            input.removeField("for");
            input.removeField("value");
            input.removeField("valueType");
        }
    },

    init: function() {
        this.appendDummyInput('DUMMY')
            .appendField("steer motors")
            .appendField(new Blockly.FieldDropdown([["A+B","0"], ["A+D","1"], ["B+C","2"], ["C+D","3"]]), "ports")
            .appendField(" turn ratio")
            .appendField(new Blockly.FieldNumber(0, -200, 200), "trunRatioValue")
            .appendField(" speed")
            .appendField(new Blockly.FieldNumber(50, -100, 100), "speedValue")
            .appendField("%  ")
            .appendField(new Blockly.FieldCheckbox(false,this.validator), "additional");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(135);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['pauseuntil_ready'] = {
    dropdownValidator: function(newValue) {
        var sourceBlock = this.getSourceBlock();
        if(newValue=="2"){
            sourceBlock.getField("port").menuGenerator_=[["A+B","0"], ["A+D","1"], ["B+C","2"], ["C+D","3"]];
            switch(sourceBlock.getField("port").selectedOption_[1]){
                case "0":
                    sourceBlock.getField("port").selectedOption_=["A+B","0"];
                    break;
                case "1":
                    sourceBlock.getField("port").selectedOption_=["A+D","1"];
                    break;
                case "2":
                    sourceBlock.getField("port").selectedOption_=["B+C","2"];
                    break;
                case "3":
                    sourceBlock.getField("port").selectedOption_=["C+D","3"];
                    break;
            }
            sourceBlock.getField("port").forceRerender();
        }else{
            sourceBlock.getField("port").menuGenerator_=[["A","0"], ["B","1"], ["C","2"], ["D","3"]];
            switch(sourceBlock.getField("port").selectedOption_[1]){
                case "0":
                    sourceBlock.getField("port").selectedOption_=["A","0"];
                    break;
                case "1":
                    sourceBlock.getField("port").selectedOption_=["B","1"];
                    break;
                case "2":
                    sourceBlock.getField("port").selectedOption_=["C","2"];
                    break;
                case "3":
                    sourceBlock.getField("port").selectedOption_=["D","3"];
                    break;
            }
            sourceBlock.getField("port").forceRerender();
        }
        return newValue;
    },

    init: function() {
        this.appendDummyInput()
            .appendField("pause until ")
            .appendField(new Blockly.FieldDropdown([["medium motor","0"], ["large motor","1"], ["two large motors","2"]],this.dropdownValidator), "motors")
            .appendField(" ")
            .appendField(new Blockly.FieldDropdown([["A","0"], ["B","1"], ["C","2"], ["D","3"]]), "port")
            .appendField("ready");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(135);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['pauseuntil_stalled'] = {
    dropdownValidator: function(newValue) {
        var sourceBlock = this.getSourceBlock();
        if(newValue=="2"){
            sourceBlock.getField("port").menuGenerator_=[["A+B","0"], ["A+D","1"], ["B+C","2"], ["C+D","3"]];
            switch(sourceBlock.getField("port").selectedOption_[1]){
                case "0":
                    sourceBlock.getField("port").selectedOption_=["A+B","0"];
                    break;
                case "1":
                    sourceBlock.getField("port").selectedOption_=["A+D","1"];
                    break;
                case "2":
                    sourceBlock.getField("port").selectedOption_=["B+C","2"];
                    break;
                case "3":
                    sourceBlock.getField("port").selectedOption_=["C+D","3"];
                    break;
            }
            sourceBlock.getField("port").forceRerender();
        }else{
            sourceBlock.getField("port").menuGenerator_=[["A","0"], ["B","1"], ["C","2"], ["D","3"]];
            switch(sourceBlock.getField("port").selectedOption_[1]){
                case "0":
                    sourceBlock.getField("port").selectedOption_=["A","0"];
                    break;
                case "1":
                    sourceBlock.getField("port").selectedOption_=["B","1"];
                    break;
                case "2":
                    sourceBlock.getField("port").selectedOption_=["C","2"];
                    break;
                case "3":
                    sourceBlock.getField("port").selectedOption_=["D","3"];
                    break;
            }
            sourceBlock.getField("port").forceRerender();
        }
        return newValue;
    },

    init: function() {
        this.appendDummyInput()
            .appendField("pause until ")
            .appendField(new Blockly.FieldDropdown([["medium motor","0"], ["large motor","1"], ["two large motors","2"]],this.dropdownValidator), "motors")
            .appendField(" ")
            .appendField(new Blockly.FieldDropdown([["A","0"], ["B","1"], ["C","2"], ["D","3"]]), "port")
            .appendField("stalled");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(135);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['stop'] = {
    dropdownValidator: function(newValue) {
        var sourceBlock = this.getSourceBlock();
        if(newValue=="2"){
            sourceBlock.getField("port").menuGenerator_=[["A+B","0"], ["A+D","1"], ["B+C","2"], ["C+D","3"]];
            switch(sourceBlock.getField("port").selectedOption_[1]){
                case "0":
                    sourceBlock.getField("port").selectedOption_=["A+B","0"];
                    break;
                case "1":
                    sourceBlock.getField("port").selectedOption_=["A+D","1"];
                    break;
                case "2":
                    sourceBlock.getField("port").selectedOption_=["B+C","2"];
                    break;
                case "3":
                    sourceBlock.getField("port").selectedOption_=["C+D","3"];
                    break;
            }
            sourceBlock.getField("port").forceRerender();
        }else{
            sourceBlock.getField("port").menuGenerator_=[["A","0"], ["B","1"], ["C","2"], ["D","3"]];
            switch(sourceBlock.getField("port").selectedOption_[1]){
                case "0":
                    sourceBlock.getField("port").selectedOption_=["A","0"];
                    break;
                case "1":
                    sourceBlock.getField("port").selectedOption_=["B","1"];
                    break;
                case "2":
                    sourceBlock.getField("port").selectedOption_=["C","2"];
                    break;
                case "3":
                    sourceBlock.getField("port").selectedOption_=["D","3"];
                    break;
            }
            sourceBlock.getField("port").forceRerender();
        }
        return newValue;
    },

    init: function() {
        this.appendDummyInput()
            .appendField("stop")
            .appendField(new Blockly.FieldDropdown([["medium motor","0"], ["large motor","1"], ["two large motors","2"]],this.dropdownValidator), "motors")
            .appendField(" ")
            .appendField(new Blockly.FieldDropdown([["A","0"], ["B","1"], ["C","2"], ["D","3"]]), "port");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(135);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['reset'] = {
    dropdownValidator: function(newValue) {
        var sourceBlock = this.getSourceBlock();
        if(newValue=="2"){
            sourceBlock.getField("port").menuGenerator_=[["A+B","0"], ["A+D","1"], ["B+C","2"], ["C+D","3"]];
            switch(sourceBlock.getField("port").selectedOption_[1]){
                case "0":
                    sourceBlock.getField("port").selectedOption_=["A+B","0"];
                    break;
                case "1":
                    sourceBlock.getField("port").selectedOption_=["A+D","1"];
                    break;
                case "2":
                    sourceBlock.getField("port").selectedOption_=["B+C","2"];
                    break;
                case "3":
                    sourceBlock.getField("port").selectedOption_=["C+D","3"];
                    break;
            }
            sourceBlock.getField("port").forceRerender();
        }else{
            sourceBlock.getField("port").menuGenerator_=[["A","0"], ["B","1"], ["C","2"], ["D","3"]];
            switch(sourceBlock.getField("port").selectedOption_[1]){
                case "0":
                    sourceBlock.getField("port").selectedOption_=["A","0"];
                    break;
                case "1":
                    sourceBlock.getField("port").selectedOption_=["B","1"];
                    break;
                case "2":
                    sourceBlock.getField("port").selectedOption_=["C","2"];
                    break;
                case "3":
                    sourceBlock.getField("port").selectedOption_=["D","3"];
                    break;
            }
            sourceBlock.getField("port").forceRerender();
        }
        return newValue;
    },

    init: function() {
        this.appendDummyInput()
            .appendField("reset")
            .appendField(new Blockly.FieldDropdown([["medium motor","0"], ["large motor","1"], ["two large motors","2"]],this.dropdownValidator), "motors")
            .appendField(" ")
            .appendField(new Blockly.FieldDropdown([["A","0"], ["B","1"], ["C","2"], ["D","3"]]), "port");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(135);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['stopallmotors'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("stop all motors");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(135);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['resetallmotors'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("reset all motors");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(135);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['on_touch'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("on touch")
            .appendField(new Blockly.FieldDropdown([["1","0"], ["2","1"], ["3","2"], ["4","3"]]), "Port")
            .appendField(new Blockly.FieldDropdown([["pressed","0"], ["bumped","1"], ["released","2"]]), "State");
        this.appendStatementInput("Statement")
            .setCheck(null);
        this.setColour(330);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['pause_until_touch'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("pause until touch")
            .appendField(new Blockly.FieldDropdown([["1","0"], ["2","1"], ["3","2"], ["4","3"]]), "Port")
            .appendField(new Blockly.FieldDropdown([["pressed","0"], ["bumped","1"], ["released","2"]]), "State");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(330);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['touch_n_is_pressed'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("touch")
            .appendField(new Blockly.FieldDropdown([["1","0"], ["2","1"], ["3","2"], ["4","3"]]), "Port")
            .appendField("is pressed");
        this.setOutput(true, "Boolean");
        this.setColour(330);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['on_color_sensor_detected'] = {
    init: function() {
        Blockly.FieldColour.COLOURS = [
            '#FF0000', '#FFFF00', '#0000FF',
            '#00FF00', '#000000', '#A52A2A','#FFFFFF'];
        Blockly.FieldColour.TITLES = [
            'Red', 'Yellow', 'Blue',
            'Green', 'Black', 'Brown','White'];
        Blockly.FieldColour.COLUMNS = 2;
        this.appendDummyInput()
            .appendField("on color sensor")
            .appendField(new Blockly.FieldDropdown([["1","0"], ["2","1"], ["3","2"], ["4","3"]]), "Port")
            .appendField("detected")
            .appendField(new Blockly.FieldColour("#3333ff"), "Color");
        this.appendStatementInput("Statement")
            .setCheck(null);
        this.setColour(330);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['pause_until_color'] = {
    init: function() {
        Blockly.FieldColour.COLOURS = [
            '#FF0000', '#FFFF00', '#0000FF',
            '#00FF00', '#000000', '#A52A2A','#FFFFFF'];
        Blockly.FieldColour.TITLES = [
            'Red', 'Yellow', 'Blue',
            'Green', 'Black', 'Brown','White'];
        Blockly.FieldColour.COLUMNS = 2;
        this.appendDummyInput()
            .appendField("pause until color sensor")
            .appendField(new Blockly.FieldDropdown([["1","0"], ["2","1"], ["3","2"], ["4","3"]]), "Port")
            .appendField("detected")
            .appendField(new Blockly.FieldColour("#3333ff"), "Color");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(330);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['is_color_sensor_n_detected'] = {
    init: function() {
        Blockly.FieldColour.COLOURS = [
            '#FF0000', '#FFFF00', '#0000FF',
            '#00FF00', '#000000', '#A52A2A','#FFFFFF'];
        Blockly.FieldColour.TITLES = [
            'Red', 'Yellow', 'Blue',
            'Green', 'Black', 'Brown','White'];
        Blockly.FieldColour.COLUMNS = 2;
        this.appendDummyInput()
            .appendField("is color sensor")
            .appendField(new Blockly.FieldDropdown([["1","0"], ["2","1"], ["3","2"], ["4","3"]]), "Port")
            .appendField("detected")
            .appendField(new Blockly.FieldColour("#3333ff"), "Color");
        this.setOutput(true, "Boolean");
        this.setColour(330);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['color_sensor_n_color'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("color sensor")
            .appendField(new Blockly.FieldDropdown([["1","0"], ["2","1"], ["3","2"], ["4","3"]]), "Port")
            .appendField("color");
        this.setOutput(true, "Number");
        this.setColour(330);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['color'] = {
    init: function() {
        Blockly.FieldColour.COLOURS = [
            '#FF0000', '#FFFF00', '#0000FF',
            '#00FF00', '#000000', '#A52A2A','#FFFFFF'];
        Blockly.FieldColour.TITLES = [
            'Red', 'Yellow', 'Blue',
            'Green', 'Black', 'Brown','White'];
        Blockly.FieldColour.COLUMNS = 2;
        this.appendDummyInput()
            .appendField("color")
            .appendField(new Blockly.FieldColour("#ff0000"), "Color");
        this.setOutput(true, "Number");
        this.setColour(330);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['on_color_sensor_light'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("on color sensor")
            .appendField(new Blockly.FieldDropdown([["1","0"], ["2","1"], ["3","2"], ["4","3"]]), "Port")
            .appendField(new Blockly.FieldDropdown([["reflected light","0"]/*, ["ambient light","1"], ["reflected light (raw)","2"]*/]), "Light_Type")
            .appendField(new Blockly.FieldDropdown([["dark","0"], ["bright","1"]]), "State");
        this.appendStatementInput("Statement")
            .setCheck(null);
        this.setColour(330);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['pause_until_color_light'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("pause until color sensor")
            .appendField(new Blockly.FieldDropdown([["1","0"], ["2","1"], ["3","2"], ["4","3"]]), "Port")
            .appendField(new Blockly.FieldDropdown([["reflected light","0"]/*, ["ambient light","1"], ["reflected light (raw)","2"]*/]), "Light_Type")
            .appendField(new Blockly.FieldDropdown([["dark","0"], ["bright","1"]]), "State");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(330);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['color_sensor_light'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("color sensor")
            .appendField(new Blockly.FieldDropdown([["1","0"], ["2","1"], ["3","2"], ["4","3"]]), "Port")
            .appendField(new Blockly.FieldDropdown([["reflected light","0"]/*, ["ambient light","1"], ["reflected light (raw)","2"]*/]), "Light_Type");
        this.setOutput(true, "Number");
        this.setColour(330);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['color_sensor_rgb_raw'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("color sensor")
            .appendField(new Blockly.FieldDropdown([["1","0"], ["2","1"], ["3","2"], ["4","3"]]), "Port")
            .appendField("RGB raw");
        this.setOutput(true, "Array");
        this.setColour(330);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['on_ultrasonic_detected'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("on ultrasonic")
            .appendField(new Blockly.FieldDropdown([["1","0"], ["2","1"], ["3","2"], ["4","3"]]), "Port")
            .appendField("object")
            .appendField(new Blockly.FieldDropdown([["detected","0"], ["near","1"]]), "State");
        this.appendStatementInput("Statement")
            .setCheck(null);
        this.setColour(330);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['pause_until_ultrasonic'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("pause until ultrasonic")
            .appendField(new Blockly.FieldDropdown([["1","0"], ["2","1"], ["3","2"], ["4","3"]]), "Port")
            .appendField("object")
            .appendField(new Blockly.FieldDropdown([["detected","0"], ["near","1"]]), "State");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(330);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['ultrasonic_distance'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("ultrasonic")
            .appendField(new Blockly.FieldDropdown([["1","0"], ["2","1"], ["3","2"], ["4","3"]]), "Port")
            .appendField("distance");
        this.setOutput(true, "Number");
        this.setColour(330);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['gyro_rate'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("gyro")
            .appendField(new Blockly.FieldDropdown([["1","0"], ["2","1"], ["3","2"], ["4","3"]]), "Port")
            .appendField("rate");
        this.setOutput(true, "Number");
        this.setColour(330);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['gyro_angle'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("gyro")
            .appendField(new Blockly.FieldDropdown([["1","0"], ["2","1"], ["3","2"], ["4","3"]]), "Port")
            .appendField("angle");
        this.setOutput(true, "Number");
        this.setColour(330);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['pause_until_gyro'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("pause until gyro")
            .appendField(new Blockly.FieldDropdown([["1","0"], ["2","1"], ["3","2"], ["4","3"]]), "Port")
            .appendField("rotated")
            .appendField(new Blockly.FieldNumber(0), "Angle")
            .appendField("degrees");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(330);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['reset_gyro'] = {
    init: function() {
        this.appendDummyInput()
            .appendField("reset gyro")
            .appendField(new Blockly.FieldDropdown([["1","0"], ["2","1"], ["3","2"], ["4","3"]]), "Port");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(330);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['onstart'] = {
  init: function() {
    this.appendDummyInput()
      .appendField(new Blockly.FieldLabelSerializable("on start"), "start");
    this.appendStatementInput("Statement")
      .setCheck(null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

Blockly.Blocks['forever'] = {
  init: function() {
    this.appendDummyInput()
      .appendField(new Blockly.FieldLabelSerializable("forever"), "start");
    this.appendStatementInput("Statement")
      .setCheck(null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
  }
};


Blockly.Blocks['repeat'] = {
  init: function() {
    this.appendDummyInput()
      .appendField("repeat")
      .appendField(new Blockly.FieldNumber(5, 0), "repeatTimes")
      .appendField(" times");
    this.appendStatementInput("Statement")
      .setCheck(null);
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
  }
};


Blockly.Blocks['pause'] = {
  init: function() {
    this.appendDummyInput()
      .appendField("pause")
      .appendField(new Blockly.FieldNumber(1, 1), "seconds")
      .appendField(" ms");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

Blockly.Blocks['while_loop'] = {
  init: function() {
    this.appendValueInput("value")
      .setCheck("Boolean")
      .appendField("while");
    this.appendStatementInput("statement")
      .setCheck(null)
      .appendField("do");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

Blockly.Blocks['continue_block'] = {
  init: function() {
    this.appendDummyInput()
      .appendField("continue");
    this.setPreviousStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

Blockly.Blocks['break_block'] = {
  init: function() {
    this.appendDummyInput()
      .appendField("break");
    this.setPreviousStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

Blockly.Blocks['function_container'] = {
  init: function() {
    var nameField = new Blockly.FieldTextInput('do something',
      Blockly.Procedures.rename);
    nameField.setSpellcheck(false);
    this.appendDummyInput()
      .appendField("function")
      .appendField(nameField, "fName");
    this.appendStatementInput("Statement")
      .setCheck(null);
    this.setColour(290);
    this.setTooltip("");
    this.setHelpUrl("");
  }
};

Blockly.Blocks['function_caller'] = {
  init: function() {
    var nameField = new Blockly.FieldTextInput('do something',
      Blockly.Procedures.rename);
    this.appendDummyInput()
      .appendField("call function")
      .appendField(nameField, "fName");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(290);
    this.setTooltip("");
    this.setHelpUrl("");
  }
};
